
import {
    auto as followSystemColorScheme,
} from 'darkreader';
import BigNumber from "bignumber.js";

followSystemColorScheme({});
BigNumber.config({
    FORMAT: {
        prefix: '',
        decimalSeparator: ',',
        groupSeparator: '.',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: ' ',
        fractionGroupSize: 0,
        suffix: ''
    },
})

export { };

declare global {
    interface Window { render: Function; }
}

window.render = function () {
    const income: BigNumber = BigNumber(BigNumber((document.querySelector("#income") as HTMLInputElement).value).toFixed(0, BigNumber.ROUND_FLOOR));

    debug(income.toString());

    (document.querySelector("#incomeFormatted") as HTMLSpanElement).textContent = `${income.toFormat()}€`;

    const taxSumTotalUnrounded: BigNumber = getTaxSumTotal(income);
    const taxSumTotalRounded: BigNumber = toFixed2RoundFloor(getTaxSumTotal(income));
    const taxSumTotalPercent: BigNumber = taxSumTotalRounded.dividedBy(income).multipliedBy(100);

    (document.querySelector("#taxSumTotal") as HTMLSpanElement).textContent = `${taxSumTotalRounded.toFormat()}€`;
    (document.querySelector("#taxSumTotalPercent") as HTMLSpanElement).textContent = `${taxSumTotalPercent.toFixed(2)}%`;
    (document.querySelector("#incomePostTax") as HTMLSpanElement).textContent = `${income.minus(taxSumTotalRounded).toFormat()}€`;

    // Grenzsteuersatz (= Steuersatz für jeden weiteren €)
    const taxGrenzsteuerPercent: BigNumber = getTaxSumTotal(income.plus(1)).minus(taxSumTotalUnrounded).times(100);
    (document.querySelector("#taxGrenzsteuerPercent") as HTMLSpanElement).textContent = `${taxGrenzsteuerPercent.toFixed(2)}%`;
}

function getTaxSumTotal(income: BigNumber) {
    const x: BigNumber = income;
    const y: BigNumber = income.minus(10908).dividedBy(10000);
    const z: BigNumber = income.minus(15999).dividedBy(10000);

    if (income.isLessThanOrEqualTo(10908)) {
        return BigNumber(0);
    }
    if (income.isLessThanOrEqualTo(15999)) {
        return y.times("979.18").plus(1400).times(y);
    }
    if (income.isLessThanOrEqualTo(62809)) {
        return z.times("192.59").plus(2397).times(z).plus("966.53");
    }
    if (income.isLessThanOrEqualTo(277825)) {
        return x.times("0.42").minus("9972.98");
    }
    return x.times("0.45").minus("18307.73");
}

function toFixed2RoundFloor(x: BigNumber): BigNumber {
    return BigNumber(x.toFixed(2, BigNumber.ROUND_FLOOR))
}

function debug(...data: any[]) {
    console.log(...data);
}

window.addEventListener("load", () => window.render());
(document.querySelector("#input") as HTMLInputElement).addEventListener("keyup", () => window.render());